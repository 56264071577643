import { useMediaQuery } from "react-responsive";
import { NAV } from "../constants/paths";

import lom from "../assets/img/brands/lambo-owners-malaysia.png";
import recstorm from "../assets/img/brands/recstorm.png";
import momenz from "../assets/img/brands/momenz.png";
import troopers from "../assets/img/brands/troopers.png";
import scientex from "../assets/img/brands/scientex.png";
import dsHome from "../assets/img/brands/ds-home.png";
import oversteer from "../assets/img/brands/oversteer-sq.png";
import ferrari from "../assets/img/brands/ferrari-owners.png";
import robbReport from "../assets/img/brands/robb-report.png";
import chery from "../assets/img/brands/chery.png";
import controls from "../assets/img/brands/controls.png";
import gac from "../assets/img/brands/gac.png";
import giti from "../assets/img/brands/giti.png";
import jaecoo from "../assets/img/brands/jaecoo.png";
import kazuto from "../assets/img/brands/kazuto.png";
import autoshow from "../assets/img/brands/mas.png";
import mg from "../assets/img/brands/mg.png";
import fanatec from "../assets/img/brands/fanatec.png";
import digitalPhoto from "../assets/img/services/digital.jpg";
import videography from "../assets/img/services/video.jpg";
import hybrid from "../assets/img/services/hybrid.jpg";
import film from "../assets/img/services/film.jpg";
import drone from "../assets/img/services/drone.jpg";
import web from "../assets/img/services/web.jpg";
import zbom from "../assets/img/services/zbom.jpg";
import auto from "../assets/img/services/auto.jpg";
import pov from "../assets/img/services/pov.jpg";
import solarvest from "../assets/img/brands/solarvest-logo.png";
import mrLube from "../assets/img/brands/mr-lube.jpg";
import motul from "../assets/img/brands/Motul_logo.png";
import tas from "../assets/img/brands/tas.png";

import { LazyLoadImage } from "react-lazy-load-image-component";

const Services = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  return (
    <>
      <section id="services" className="services">
        <div className="container">
          <div className="section-title">
            <span>My Services</span>
            <h2>My Services</h2>
          </div>

          <div className="row">
            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-5">
              <a className="icon-box" href={NAV.PORTFOLIO}>
                <div className="services-icon">
                  <LazyLoadImage
                    src={digitalPhoto}
                    className="services-digital"
                    alt="Digital Photography"
                    effect="blur"
                  />
                </div>
                <h4 className="title">Digital Photography</h4>
                <p className="description">
                  From high paced motorsports and events to studio based product
                  photography, andriantam shoots various types of genres suited
                  for your needs, fueled by a passion in photography over 15
                  years inspired by National Geographic.
                </p>
              </a>
            </div>

            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-5">
              <a className="icon-box" href={`${NAV.PORTFOLIO}/videography`}>
                <div className="services-icon">
                  <LazyLoadImage
                    src={videography}
                    className="services-video"
                    alt="Videography"
                    effect="blur"
                  />
                </div>
                <h4 className="title">Videography</h4>
                <p className="description">
                  Focused on capturing moments in a cinematic tone, andriantam
                  captures videos in wide format and vertical reels for various
                  purposes from film sets to on ground events. andriantam
                  previously carries the name of Tam's Up Productions.
                </p>
              </a>
            </div>

            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-5">
              <a className="icon-box" href={NAV.PORTFOLIO}>
                <div className="services-icon">
                  <LazyLoadImage
                    src={hybrid}
                    className="services-video"
                    alt="Hybrid Photography"
                    effect="blur"
                  />
                </div>
                <h4 className="title">Hybrid Shooting</h4>
                <p className="description">
                  Some clients just prefer a one-man-show to get both photos and
                  videos at the same time. The disclaimer is that moments shot
                  on photo may not be on video, and vice versa. As long clients
                  agree to this, we do it.
                </p>
              </a>
            </div>

            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-5">
              <a className="icon-box" href={NAV.PORTFOLIO}>
                <div className="services-icon">
                  <LazyLoadImage
                    src={film}
                    className="services-digital"
                    alt="Film Photography"
                    effect="blur"
                  />
                </div>
                <h4 className="title">Film Photography</h4>
                <p className="description">
                  Andrian, being technical, has been curious about the roots and
                  fundamentals of photography that he has dived into film
                  photography in both medium and 35mm format. He has shot
                  weddings in film before.
                </p>
              </a>
            </div>

            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-5">
              <a className="icon-box" href={NAV.PORTFOLIO}>
                <div className="services-icon">
                  <LazyLoadImage
                    src={drone}
                    className="services-digital"
                    alt="Drone Pilot"
                    effect="blur"
                  />
                </div>
                <h4 className="title">Drone Piloting</h4>
                <p className="description">
                  To get a different angle, andriantam has been flying drones
                  from the DJI Spark to DJI Mini 3 Pro for capturing cinematic
                  aerial B-rolls.
                </p>
              </a>
            </div>

            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-5">
              <a className="icon-box" href={NAV.PORTFOLIO}>
                <div className="services-icon">
                  <LazyLoadImage
                    src={pov}
                    className="services-digital"
                    alt="POV Camera"
                    effect="blur"
                  />
                </div>
                <h4 className="title">Helmet Camera Operator</h4>
                <p className="description">
                  andriantam is also a POV talent that wears a POV camera mount
                  helmet to capture videos from a Point-of-view angle.
                </p>
              </a>
            </div>

            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-5">
              <a
                className="icon-box"
                href="https://zbommalaysia.com.my/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="services-icon">
                  <LazyLoadImage
                    src={zbom}
                    className="services-digital"
                    alt="ZBOM Malaysia"
                    effect="blur"
                  />
                </div>
                <h4 className="title">
                  Kitchen and Wardrobe Cabinet Consultation
                </h4>
                <p className="description">
                  andriantam is an Interior Design agent under ZBOM Malaysia,
                  Kuala Lumpur, providing consultation for rennovation solutions
                  for your home and offices. Our showroom is located at Sunway
                  Velocity, Cheras.
                </p>
              </a>
            </div>

            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-5">
              <a className="icon-box" href={NAV.PORTFOLIO}>
                <div className="services-icon">
                  <LazyLoadImage
                    src={auto}
                    className="services-digital"
                    alt="Automotive Parts"
                    effect="blur"
                  />
                </div>
                <h4 className="title">Automotive Parts Dealer</h4>
                <p className="description">
                  andriantam is an automotive online dealer for Nankang
                  Motorsports Malaysia tyres and Takumi Motor Oil Malaysia.
                </p>
              </a>
            </div>

            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-5">
              <a className="icon-box" href={NAV.RESUME}>
                <div className="services-icon">
                  <LazyLoadImage
                    src={web}
                    className="services-digital"
                    alt="Software Engineer"
                    effect="blur"
                  />
                </div>
                <h4 className="title">Web Development</h4>
                <p className="description">
                  andriantam has been building software for various industries
                  and clients across multiple countries for almost 10 years.
                  Currently, he only provides technical consultation and builds
                  tech teams.
                </p>
              </a>
            </div>
          </div>

          <div className="row">
            <div className="btn-wrap">
              <a href={NAV.PORTFOLIO} className="btn-action">
                Go To My Portfolio
              </a>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="section-title">
            {/* <span>Clients / Brands / Events Served</span> */}
            <h2>Clients / Brands / Events Served</h2>
          </div>

          <div className="row">
            <div className="brand-img">
              <a
                href="https://www.instagram.com/recstorm_pictures/"
                target="blank"
              >
                <LazyLoadImage
                  src={recstorm}
                  className="recstorm-pictures"
                  alt="recstorm pictures"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://momenz.my/" target="blank">
                <LazyLoadImage
                  src={momenz}
                  className="momenz"
                  alt="momenz creation"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://www.instagram.com/p/DA-rMgjy8pF/" target="blank">
                <LazyLoadImage
                  src={solarvest}
                  className="solarvest"
                  alt="Solarvest"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a
                href="https://www.facebook.com/share/p/yymFNzH7ZUu7t3RL"
                target="blank"
              >
                <LazyLoadImage
                  src={mrLube}
                  className="mr-lube"
                  alt="Mr Lubricant"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://www.instagram.com/p/DCqX60PS2j2/" target="blank">
                <LazyLoadImage
                  src={motul}
                  className="motul"
                  alt="motul"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a
                href="https://www.instagram.com/togelifestyle/reel/DAXfGWFyb3M/"
                target="blank"
              >
                <LazyLoadImage
                  src={oversteer}
                  className="oversteer"
                  alt="Oversteer Movie"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://www.instagram.com/p/DA-rMgjy8pF/" target="blank">
                <LazyLoadImage
                  src={solarvest}
                  className="solarvest"
                  alt="Solarvest"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://www.instagram.com/p/DCMhx-NSxQJ" target="blank">
                <LazyLoadImage
                  src={tas}
                  className="tas"
                  alt="Tokyo Auto Salon KL"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://autoshow.my/" target="blank">
                <LazyLoadImage
                  src={autoshow}
                  className="malaysia-autoshow"
                  alt="Malaysia Autoshow"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://www.troopers.com.my/home" target="blank">
                <LazyLoadImage
                  src={troopers}
                  className="troopers"
                  alt="troopers my part time manpower made easy"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://scientex.com.my/" target="blank">
                <LazyLoadImage
                  src={scientex}
                  className="scientex"
                  alt="scientex healthy happy friendly"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://www.gt-world-challenge.com/" target="blank">
                <LazyLoadImage
                  src={fanatec}
                  className="fanatec-gt"
                  alt="Fanatec GT World Challenge Sepang Malaysia"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://robbreport.com.my/" target="blank">
                <LazyLoadImage
                  src={robbReport}
                  className="robb-report"
                  alt="Robb Report"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://focm.com.my/" target="blank">
                <LazyLoadImage
                  src={ferrari}
                  className="ferrari"
                  alt="Ferrari Owners Club Malaysia"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://dshome2u.com.my/" target="blank">
                <LazyLoadImage
                  src={dsHome}
                  className="ds-home"
                  alt="ds home logo"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://www.chery.my/" target="blank">
                <LazyLoadImage
                  src={chery}
                  className="chery"
                  alt="Chery Malaysia"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://www.controls.com.my/" target="blank">
                <LazyLoadImage
                  src={controls}
                  className="controls-switchgear"
                  alt="Controls Switchgear"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://gacmotor.com.my/" target="blank">
                <LazyLoadImage
                  src={gac}
                  className="gac"
                  alt="GAC Motors"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://www.giti.com.sg/" target="blank">
                <LazyLoadImage
                  src={giti}
                  className="giti"
                  alt="Giti Tyres Singapore"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://www.mgmalaysia.com/" target="blank">
                <LazyLoadImage
                  src={mg}
                  className="mg"
                  alt="MG Motor Malaysia"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://www.jaecoo.com.my/" target="blank">
                <LazyLoadImage
                  src={jaecoo}
                  className="jaecoo"
                  alt="Jaecoo Malaysia"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://www.youtube.com/c/KazutoGarage" target="blank">
                <LazyLoadImage
                  src={kazuto}
                  className="kazuto"
                  alt="Kazuto Garage"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a
                href="https://www.facebook.com/LamborghiniOwnersMalaysia"
                target="blank"
              >
                <LazyLoadImage
                  src={lom}
                  className="lom"
                  alt="lamborghini owners malaysia"
                  effect="blur"
                />
              </a>
            </div>
          </div>

          <div className="row">
            <div className="btn-wrap">
              <a href={NAV.PORTFOLIO} className="btn-action">
                Go To My Portfolio
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
